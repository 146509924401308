import * as React from 'react'
import { useForm } from 'react-hook-form'
import { Flex, Button } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { object, string } from 'yup'
import BasePage from '../../components/basePage'
import Input from '../../components/input'
import { setIsAdmin } from '../../helpers/database'

const AdminLogin = ({ setUserIsAdmin }) => {
	const [isLoading, setIsLoading] = React.useState(false)

	const resolver = yupResolver(
		object()
			.shape({
				password: string().trim().required()
			})
			.required()
	)
	const {
		register,
		handleSubmit,
		setError,
		formState: { errors }
	} = useForm({
		defaultValues: { password: '' },
		resolver,
		mode: 'onChange',
		reValidateMode: 'onChange',
		shouldUseNativeValidation: false
	})

	const onSubmit = async ({ password }) => {
		setIsLoading(true)
		if (password === 'teamwork') {
			setIsAdmin(true)
			setUserIsAdmin(true)
		} else {
			setError('password', { message: 'incorrect password' })
		}
		return setIsLoading(false)
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<BasePage
				showMenuBtn={true}
				theme="dark"
				primaryBtn={
					<Button
						mt="8px"
						variant="primary"
						isLoading={isLoading}
						disabled={isLoading}
						type="submit"
					>
						Continue
					</Button>
				}
			>
				<Flex alignItems="center" flexDirection="column" mt="0">
					<Input
						name="password"
						id="password"
						type="password"
						variant="filled"
						placeholder="Enter password"
						mb="10px"
						register={register}
						errors={errors}
					/>
				</Flex>
			</BasePage>
		</form>
	)
}

export default AdminLogin
