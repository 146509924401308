import React from 'react'
import { Button, Flex, Text, Image } from '@chakra-ui/react'
import OkSVG from '../icons/ok.svg'
import GroupSVG from '../icons/groups.svg'
import GroupWhiteSVG from '../icons/groups-white.svg'
import Avatar from '../images/avatars/1.png'
import { navigate } from 'gatsby'
import { getImageById } from '../helpers'

const TeamItem = ({ team, active }) => {
	const { id, avatar, totalMembers, name } = team

	return (
		<Button
			variant="teamItem"
			active={!!active}
			onClick={() => navigate(`${id}/`)}
			w="100%"
			maxW="100%"
		>
			<Flex
				justifyContent="center"
				alignItems="center"
				width="100%"
				maxW="100%"
			>
				<Image
					src={avatar ? getImageById(avatar) : Avatar}
					width="52px"
					height="52px"
					alt="Team Icon"
				/>
				<Flex
					direction="column"
					justifyContent="center"
					alignItems="flex-start"
					overflow="hidden"
					maxW="100%"
					w="100%"
					mx="10px"
				>
					<Text
						variant="lg"
						color={active ? 'black' : 'white'}
						mx="0"
						mb="0"
						mt="5px"
						w="100%"
						overflow="hidden"
						textOverflow="ellipsis"
						whiteSpace="nowrap"
					>
						{name || ''}
					</Text>
					<Flex
						direction="row"
						justifyContent="flex-start"
						alignItems="center"
						mt="2px"
					>
						{active ? <GroupSVG /> : <GroupWhiteSVG />}
						<Text
							variant="s"
							color={active ? 'black' : 'white'}
							ml="4px"
						>
							{totalMembers || 0} players
						</Text>
					</Flex>
				</Flex>
				{!!active && <OkSVG />}
			</Flex>
		</Button>
	)
}

export default TeamItem
